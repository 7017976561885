var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Cookie Script Run Order ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" Please identify the Run Order in which you wish your Cookie Scripts to execute. ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.runOrderItems,"hide-default-footer":"","items-per-page":50},scopedSlots:_vm._u([{key:"item.cookieScriptName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCookieScriptName(item))+" ")]}},{key:"item.cookieCategoryName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCookieCategoryName(item))+" ")]}},{key:"item.isRunFirst",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getIsRunFirst(item) ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"item.runOrder",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},(_vm.userCanCreateUpdate)?{key:"item.action",fn:function(ref){
var index = ref.index;
return [(_vm.showUpArrow(index))?_c('IconButton',{on:{"click":function($event){return _vm.moveRunOrderUp(index)}}},[_vm._v(" mdi-arrow-up ")]):_vm._e(),(_vm.showDownArrow(index))?_c('IconButton',{on:{"click":function($event){return _vm.moveRunOrderDown(index)}}},[_vm._v(" mdi-arrow-down ")]):_vm._e()]}}:null],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }