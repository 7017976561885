import { format } from 'date-fns'
import { DATE_FORMAT } from '../../date-formatting.js'
import { configApi } from '../../http-client.js'

export const getCookieCategories = () => {
	return configApi.get('/api/cookieCategory').then(data => ({
		cookieCategories: data.data.map(cookieCategory => ({
			...cookieCategory,
			id: cookieCategory.categoryId,
			createdDate: format(new Date(cookieCategory.createdDate), DATE_FORMAT)
		}))
	}))
}

export const createCookieCategory = cookieCategory => {
	return configApi.post('/api/cookieCategory', cookieCategory)
}
export const editCookieCategory = cookieCategory => {
	return configApi.put('/api/cookieCategory', cookieCategory)
}

export const deleteCookieCategory = cookieCategoryId => {
	return configApi.delete(`/api/cookieCategory/${cookieCategoryId}`)
}

export const createCookieCategoryTranslation = translation => {
	return configApi.post('/api/CookieCategory/CreateTranslation', translation)
}

export const updateCookieCategoryTranslation = translation => {
	return configApi.put('/api/CookieCategory/UpdateTranslation', translation)
}

export const deleteCookieCategoryTranslation = categoryTranslationId => {
	return configApi.delete(`/api/CookieCategory/DeleteTranslation/${categoryTranslationId}`, categoryTranslationId)
}
