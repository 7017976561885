<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="850px">
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Create' }} Cookie Category
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model="cookieCategory.categoryName"
								:disabled="!userFullPermissions"
								label="Name *"
								rules="required"
							/>
						</v-col>
						<v-col cols="6">
							<Dropdown
								v-model="cookieCategory.brandId"
								:disabled="!userFullPermissions || isEdit || accessedViaCookieBannerConfiguration || !clientMultiBranded || singleBrand"
								:items="brandItems"
								custom-sort
								label="Brand *"
								:rules="{ required: clientMultiBranded}"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Froala
								v-if="userFullPermissions"
								v-model="cookieCategory.displayText"
								title="Display Text *"
								:rules="{required: true, max: 2000}"
							/>
							<div
								v-else
								v-dompurify-html="cookieCategory.displayText"
								class="mt-2 cassie-disabled-textarea rounded-lg"
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import Modal from '../../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import Froala from '../../../../shared/froala-editor.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import { defaultBrandInSelect, singleBrand, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../../../shared/state/brands.js'
import { createCookieCategory, editCookieCategory } from '../../../../../../../shared/utils/api/cookies/cookie-categories.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'

export default {
	components: {
		Modal,
		ValidationForm,
		IconButton,
		TextField,
		Dropdown,
		Froala,
		SecondaryActionButton,
		PrimaryActionButton
	},
	props: {
		cookieCategoryToEdit: Object,
		userFullPermissions: Boolean,
		accessedViaCookieBannerConfiguration: {
			type: Boolean,
			default: false
		},
		cookieCollectionBrandId: Number
	},
	setup () {
		return {
			brandOptions,
			showSnackbar,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand
		}
	},
	data () {
		return {
			cookieCategory: JSON.parse(JSON.stringify(this.cookieCategoryToEdit || {
				categoryName: '',
				categoryDescription: null,
				brandId: this.defaultBrandInSelect,
				isStrictlyNecessary: false,
				displayText: ''
			}))
		}
	},
	computed: {
		isEdit () {
			return !!this.cookieCategoryToEdit
		},
		brandItems () {
			// ensures when viewing universal object it displays in dropdown
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)
			if (containsUniversal) {
				return brandOptions.value
			}
			if (!this.isEdit) {
				return brandOptions.value
			}
			return [
				UNIVERSAL_BRAND_ITEM,
				...brandOptions.value
			]
		}
	},
	created () {
		if (this.accessedViaCookieBannerConfiguration && !this.isEdit) {
			this.cookieCategory.brandId = this.cookieCollectionBrandId
		}
	},
	methods: {
		async submit () {
			const cookieCategory = {}
			cookieCategory.categoryName = this.cookieCategory.categoryName
			cookieCategory.categoryDescription = this.cookieCategory.categoryName
			cookieCategory.brandId = this.cookieCategory.brandId
			cookieCategory.displayText = this.cookieCategory.displayText

			if (!this.isEdit) {
				await createCookieCategory(cookieCategory).then(response => {
					this.$emit('submit')
					this.$emit('get-new-category-id', response.data.newCookieCategoryId)
				})
				showSnackbar('Cookie Category created successfully.')
			} else {
				cookieCategory.id = this.cookieCategory.id
				cookieCategory.isStrictlyNecessary = this.cookieCategory.isStrictlyCategory
				await editCookieCategory(cookieCategory)
				showSnackbar('Cookie Category updated successfully.')
			}
			this.$emit('submit')
		}
	}
}
</script>
