<template>
	<SectionCard>
		<template #title>
			Cookie Script Run Order
		</template>
		<template #subtitle>
			Please identify the Run Order in which you wish your Cookie Scripts to execute.
		</template>
		<template #body>
			<DataTable
				:headers="tableHeaders"
				:items="runOrderItems"
				hide-default-footer
				:items-per-page="50"
			>
				<template #item.cookieScriptName="{ item }">
					{{ getCookieScriptName(item) }}
				</template>
				<template #item.cookieCategoryName="{ item }">
					{{ getCookieCategoryName(item) }}
				</template>
				<template #item.isRunFirst="{ item }">
					<v-icon small>
						{{ getIsRunFirst(item) ? 'mdi-check' : 'mdi-close' }}
					</v-icon>
				</template>
				<template #item.runOrder="{ index }">
					{{ index + 1 }}
				</template>
				<template
					v-if="userCanCreateUpdate"
					#item.action="{ index }"
				>
					<IconButton
						v-if="showUpArrow(index)"
						@click="moveRunOrderUp(index)"
					>
						mdi-arrow-up
					</IconButton>
					<IconButton
						v-if="showDownArrow(index)"
						@click="moveRunOrderDown(index)"
					>
						mdi-arrow-down
					</IconButton>
				</template>
			</DataTable>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'

export default {
	components: {
		SectionCard,
		DataTable,
		IconButton
	},
	props: {
		cookieCollectionTemplate: Object,
		allCookieScripts: Array,
		userCanCreateUpdate: Boolean
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'ID', value: 'id' },
				{ text: 'Cookie Script Name', value: 'cookieScriptName' },
				{ text: 'Cookie Category Name', value: 'cookieCategoryName' },
				{ text: 'Run First?', value: 'isRunFirst' },
				{ text: 'Run Order', value: 'runOrder' },
				{ text: 'Action', value: 'action' }
			]
		},
		strictlyScripts () {
			const scripts = this.cookieCollectionTemplate.runOrderScripts.map(script => ({
				...script,
				isStrictlyNecessary: this.allCookieScripts.find(({ id }) => id === script.id)?.cookieScriptType === 'Essential',
				isRunFirst: this.allCookieScripts.find(({ id }) => id === script.id)?.isRunFirst
			}))
			const runFirstScripts = scripts.filter(({ isRunFirst, isStrictlyNecessary }) => isRunFirst && isStrictlyNecessary).sort((a, b) => a.displayOrder - b.displayOrder)
			const nonRunFirstScripts = scripts.filter(({ isRunFirst, isStrictlyNecessary }) => !isRunFirst && isStrictlyNecessary).sort((a, b) => a.displayOrder - b.displayOrder)
			const returnScriptsStrictly = [
				...runFirstScripts,
				...nonRunFirstScripts
			]
			return returnScriptsStrictly
		},
		nonStrictlyScripts () {
			const scripts = this.cookieCollectionTemplate.runOrderScripts.map(script => ({
				...script,
				isStrictlyNecessary: this.allCookieScripts.find(({ id }) => id === script.id)?.cookieScriptType === 'Essential',
				isRunFirst: this.allCookieScripts.find(({ id }) => id === script.id)?.isRunFirst
			}))
			const runFirstScripts = scripts.filter(({ isRunFirst, isStrictlyNecessary }) => isRunFirst && !isStrictlyNecessary).sort((a, b) => a.displayOrder - b.displayOrder)
			const nonRunFirstScripts = scripts.filter(({ isRunFirst, isStrictlyNecessary }) => !isRunFirst && !isStrictlyNecessary).sort((a, b) => a.displayOrder - b.displayOrder)
			const returnScripts = [
				...runFirstScripts,
				...nonRunFirstScripts
			]
			return returnScripts
		},
		runOrderItems () {
			const scripts = [
				...this.strictlyScripts,
				...this.nonStrictlyScripts
			]
			return scripts.map((script, index) => ({
				...script,
				runOrder: index + 1
			}))
		}
	},
	methods: {
		getCookieScriptName ({ id }) {
			return this.allCookieScripts.find(script => script.id === id)?.cookieScriptName
		},
		getCookieCategoryName ({ id }) {
			return this.allCookieScripts.find(script => script.id === id)?.cookieCategoryName
		},
		getIsRunFirst ({ id }) {
			return this.allCookieScripts.find(script => script.id === id)?.isRunFirst
		},
		showUpArrow (index) {
			if (index === 0) return false
			const currentScript = this.runOrderItems[index]
			const previousScript = this.runOrderItems[index - 1]
			return (
				currentScript.isRunFirst === previousScript.isRunFirst &&
        currentScript.isStrictlyNecessary === previousScript.isStrictlyNecessary
			)
		},
		showDownArrow (index) {
			if (index === this.runOrderItems.length - 1) return false
			const currentScript = this.runOrderItems[index]
			const nextScript = this.runOrderItems[index + 1]
			return (
				currentScript.isRunFirst === nextScript.isRunFirst &&
        currentScript.isStrictlyNecessary === nextScript.isStrictlyNecessary
			)
		},
		moveRunOrderDown (index) {
			this.move(this.runOrderItems, index, index + 1)
		},
		moveRunOrderUp (index) {
			this.move(this.runOrderItems, index, index - 1)
		},
		applySort (array) {
			this.$emit('update:runOrderScripts', array)
		},
		move (array, from, to) {
			if (to >= 0 && to < array.length) {
				const arrayCopy = [...array]
				const item = arrayCopy.splice(from, 1)[0]
				arrayCopy.splice(to, 0, item)
				this.applySort(arrayCopy)
			}
		}
	}
}
</script>
