import { format } from 'date-fns'
import { DATE_FORMAT } from '../../date-formatting.js'
import { configApi } from '../../http-client.js'

export const getCookieScripts = () => {
	return configApi.get('/api/cookieScript').then(data => ({
		cookieScripts: data.data.map(cookieScript => ({
			...cookieScript,
			id: cookieScript.cookieScriptId,
			createdDate: format(new Date(cookieScript.createdDate), DATE_FORMAT),
			gtmEnabled: cookieScript.gtmConsentTypeId !== null
		}))
	}))
}

export const getCookieScript = cookieScriptChannelId => {
	return configApi.get(`/api/cookieScript/${cookieScriptChannelId}`).then(({ data }) => ({
		id: data.cookieScriptId,
		cookieScriptChannelId: data.cookieScriptChannelId,
		scriptName: data.cookieScriptName,
		brandId: data.brandId,
		type: data.cookieScriptType,
		parentCookieCategoryId: data.cookieCategoryId,
		isRunFirst: data.isRunFirst,
		gpcEnabled: data.gpcEnabled,
		displayText: data.displayText,
		cookieInfoUrl: data.informationUrl,
		browserCookieNames: data.namesOfCookiesInScript.length >= 1 ? data.namesOfCookiesInScript.split(',') : [],
		scriptExpiryInDays: data.scriptExpiryInDays,
		consentExpiryInDays: data.consentExpiryInDays,
		scriptPlacementTypeId: data.scriptPlacementTypeId,
		optInDivBody: data.bodyOptInScriptDivId,
		optInScript: data.headOptInScript,
		optInScriptBody: data.bodyOptInScript,
		optOutDivBody: data.bodyOptOutScriptDivId,
		optOutScript: data.headOptOutScript,
		optOutScriptBody: data.bodyOptOutScript,
		gtmConsentTypeId: data.gtmConsentType,
		gtmEnabled: data.gtmConsentType !== null,
		transactionTypeId: data.transactionTypeId,
		overrideUniversalConsent: data.overrideUniversalConsent
	}))
}

export const getGtmConsentTypes = () => {
	return configApi.get('/api/CookieBanner/GetGTMConsentTypes').then(({ data }) => ({
		gtmConsentTypes: data.map(gtmConsentType => ({
			text: gtmConsentType.consentType,
			value: gtmConsentType.id
		}))
	}))
}

export const createCookieScript = cookieScript => {
	return configApi.post('/api/cookieScript', cookieScript)
}
export const editCookieScript = cookieScript => {
	return configApi.put('/api/cookieScript', cookieScript)
}

export const deleteCookieScript = cookieScriptChanneld => {
	return configApi.delete(`/api/cookieScript/${cookieScriptChanneld}`)
}

export const createCookieScriptTranslation = cookieScriptTranslation => {
	return configApi.post('/api/cookieScript/CreateTranslation', cookieScriptTranslation)
}
export const updateCookieScriptTranslation = cookieScriptTranslation => {
	return configApi.put('/api/cookieScript/UpdateTranslation', cookieScriptTranslation)
}

export const deleteCookieScriptTranslation = cookieScriptTranslation => {
	return configApi.delete(`/api/cookieScript/DeleteTranslation?languageId=${cookieScriptTranslation.languageId}&channelId=${cookieScriptTranslation.channelId}`)
}
